import React, { Component, useEffect, useRef } from 'react';
import ApiRequest from '../../libs/request';
import { debounce } from '../../libs/functions';


class SuggestFriends extends Component {
    constructor(props) {
        super(props);
        this.state = {
            friends: [],
            currentIndex: 0,
            isLoading: false,
            loading: false,
        };

        this.fetchMutualFriends = this.fetchMutualFriends.bind(this);
        // this.handleSendRequest = debounce(this.sendFriendRequest.bind(this), 1000);
        this.handleSendRequest = this.sendFriendRequest.bind(this);
        this.handleRemoveFriend = this.handleRemoveFriend.bind(this);
    }

    componentDidMount() {
        this.fetchMutualFriends();
    }

    fetchMutualFriends() {
        this.setState({ isLoading: true });

        let api = new ApiRequest("get", "/user/view-requests?type=pending");
        api.send({}, (pendingResponse) => {
            const pendingRequests = pendingResponse.data.requests.map(req => req.id);

            api.url = "/user/view-friends";
            api.send({}, (friendsResponse) => {
                const existingFriends = friendsResponse.data.friends.map(friend => friend.id);

                api.url = "/user/mutual-friends";
                api.send({}, (mutualResponse) => {
                    let mutualFriends = mutualResponse.data.mutualFriendsList;

                    // Filter out friends and pending requests from mutual friends
                    mutualFriends = mutualFriends.filter(friend =>
                        !pendingRequests.includes(friend.id) && !existingFriends.includes(friend.id)
                    );

                    // Combine new mutual friends with current state and filter out duplicates by ID
                    const allFriends = [...this.state.friends, ...mutualFriends];
                    const uniqueFriends = allFriends.filter((friend, index, self) =>
                        index === self.findIndex(f => f.id === friend.id)
                    );

                    this.setState({
                        friends: uniqueFriends,
                        isLoading: false,
                    });
                });
            });
        });
    }



    sendFriendRequest(friend, index) {
        const { friends } = this.state;

        if (friends[index].requested || friends[index].requestInProgress) return;

        const updatedFriends = [...friends];
        updatedFriends[index].requestInProgress = true;
        this.setState({ friends: updatedFriends });

        var api = new ApiRequest("post", "/user/send-friend-request");
        api.send(
            { friend_id: friend.id },
            () => {
                updatedFriends[index].requested = true;
                updatedFriends[index].requestInProgress = false;
                this.setState({ friends: updatedFriends });

                setTimeout(() => {
                    this.handleRemoveFriend(index);
                }, 500);
            },
            true
        );
    }


    handleRemoveFriend(index) {
        // Remove friend from the list
        const { friends } = this.state;
        const newFriends = [...friends];
        newFriends.splice(index, 1);
        this.setState({ friends: newFriends }, () => {
            this.fetchMutualFriends(); // Fetch new data
        });

    }



    render() {
        const { friends } = this.state;

        return (
            <div>
                <h6 style={{
                    color: '#000',
                    fontSize: '18px',
                    marginTop: '20px',
                    marginLeft: '10px',
                }}>Filmmakers You Should Know</h6>
                {/* <p className='should-know'>{`(You Should Know)`}</p> */}
                <div className="suggest-friends">
                    {friends.map((friend, index) => (
                        <div
                            key={friend.id}
                            className={`sug-friend animate__animated ${index !== 0 ? 'animate__fadeInUp' : '' // Apply fadeInUp animation if not the first element
                                }`}
                        >
                            <div className="friend-info">
                                <a href={"/user/profile/" + friend.id + "#profile"}>
                                    <img
                                        src={friend.profile_photo}
                                        height="40"
                                        width="40"
                                        className='sug-frnd-img'
                                        style={{ borderRadius: "10px" }}
                                        alt={`${friend.first_name} ${friend.last_name}`}
                                    />
                                    <b className='sug-frnd-name'>
                                        {friend.first_name} {friend.last_name}
                                    </b>
                                </a>
                            </div>
                            <div className="action-buttons">
                                {!friend.requested && <button
                                    id={friend.id}
                                    className={"actionbtn suggested-cancel-btn"}
                                    type="button"
                                    onClick={() => this.handleRemoveFriend(index)}
                                >
                                    Remove
                                </button>}
                                {!friend.requested && !friend.requestInProgress && (
                                    <button
                                        id={friend.id}
                                        className={"actionbtn suggested-frnd-btn"}
                                        type="button"
                                        onClick={() => {
                                            this.handleSendRequest(friend, index)

                                        }}
                                    >
                                        Add Friend
                                    </button>
                                )}

                                {
                                    friend.requested && <button
                                        id={friend.id}
                                        className={"actionbtn requested"}
                                        type="button"
                                    >
                                        Requested
                                    </button>
                                }
                                {
                                    friend.requestInProgress && <button
                                        id={friend.id}
                                        className={"actionbtn suggested-frnd-btn"}
                                        type="button"
                                    >
                                        Requesting...
                                    </button>
                                }

                            </div>
                        </div>
                    ))}
                </div>

              

            </div>
        );
    }
}

export default SuggestFriends;

