import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import Post from "./Post";
import PhotoGallery from "./PhotoGallery";

class FeedList extends Component {
    constructor(props) {
        super(props);
        this.state = { current_page: 1, comments: [] };
    }

    render() {
        const items = [];

        this.props.posts.forEach((obj, idx) => {
            let _post = obj;
            let _asset = null;

            if (_post.media.length > 0) {
                _asset = <PhotoGallery post={_post} />;
            }
            if (_post.video && _post.video.length > 0) {
                _asset = (
                    <video controls>
                        <source src={_post.video[0].url} />
                    </video>
                );
            }
            if (_post.user) {
                items.push(
                    <Post key={`post-${idx}`} post={_post} user={this.props.user} asset={_asset} />
                );
            }
        });

        return <div>{items}</div>;
    }
}

export default FeedList;
